import * as React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import * as Styles from "./index.module.sass"
import Transition from '../components/transition'
import ReturnLink from '../components/returnlink'
import { ContextProviderComponent } from './context'

library.add(fab, fas, far)
const Layout = ({ location, children }) => {
  return (<ContextProviderComponent>
    <div className={`container is-fullhd ${Styles.fixed} ${Styles.top}`}>
      <Header></Header>
    </div>
    <main className={`container is-fullhd is-clipped ${Styles.kstWrap}`}>
      <div className={`is-invisible ${Styles.fakeHeader}`}>
        &nbsp;
      </div>
      <div className={`${Styles.isTall}`}>
        <ReturnLink curpath={location.pathname} />
        <Transition location={location}>
          {children}
        </Transition>
      </div>
      <div className={`is-invisible-desktop`}>
        <Footer></Footer>
      </div>
    </main>
    <div className={`is-invisible-touch container is-fullhd ${Styles.fixed} ${Styles.bottom}`}>
      <Footer></Footer>
    </div>
  </ContextProviderComponent>
  )
}
export default Layout
