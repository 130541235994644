import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Icon = ({ ...props }) => {
  const {icon, ...rest} = props
  return (
    <span {...rest}>
      <FontAwesomeIcon icon={icon} />
    </span>
  )
}

export default Icon
