import * as React from 'react'
import { Link } from 'gatsby'
import Icon from '../components/icon'

const ReturnLink = ({ curpath }) => {
  const gettedpath = curpath.split('/').filter(item => item);
  gettedpath.pop()
  const returnlink = gettedpath.join('/')
  return (
    <Link to={'/' + returnlink} className={(curpath === "/") ? 'is-invisible' : ''}><Icon className='icon is-small fa' icon={["fas", "long-arrow-alt-left"]} /> назад</Link>
  )
}
export default ReturnLink
//props.location.pathname
