import * as React from 'react'
import * as Styles from './button.module.sass'
const Button = ({ children, onClick, className, aslink, active, ...rest }) => {
  //check must be function
  const clickfn = (typeof onClick === 'function') ? onClick : () => { }
  return (
    <button {...rest} onClick={clickfn} className={`${Styles.button} ${className!==undefined ? className : ``} ${aslink ? Styles.aslink : `button`} ${active ? `${Styles.active} is-focused` : ``}`}>
      {children}
    </button>
  )
}

export default Button
