// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-izdeliya-bokal-js": () => import("./../../../src/pages/izdeliya/bokal.js" /* webpackChunkName: "component---src-pages-izdeliya-bokal-js" */),
  "component---src-pages-izdeliya-js": () => import("./../../../src/pages/izdeliya.js" /* webpackChunkName: "component---src-pages-izdeliya-js" */),
  "component---src-pages-izdeliya-rumka-js": () => import("./../../../src/pages/izdeliya/rumka.js" /* webpackChunkName: "component---src-pages-izdeliya-rumka-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-magaziny-js": () => import("./../../../src/pages/magaziny.js" /* webpackChunkName: "component---src-pages-magaziny-js" */)
}

