import * as React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/logo.png'
import * as Styles from "./header.module.sass"
import Menu from './menu';
import Seo from './seo';
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {
  const { site, dfltimage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        dfltimage: file(relativePath: { eq: "default.jpg" }) {
          publicURL
        }
      }
    `
  )
  return (
    <>
      <Seo
        title={`${site.siteMetadata.title}`}
        titleTemplate={`%s`}
        image={ {src: dfltimage.publicURL, width: 1200, height: 628} }
        description={`${site.siteMetadata.description}`}
      />
      <div className={`columns pt-3 has-background-black is-vcentered is-mobile ${Styles.wrapper}`}>
        <div className={'column is-narrow has-text-left'}><Link to="/"><img className={Styles.logo} src={Logo} alt="logo"></img></Link></div>
        <div className={'column has-text-right'}>
          <Menu></Menu>
        </div>
      </div>
    </>
  )
}
export default Header
