import * as React from 'react'
import { Link } from 'gatsby'
import * as Styles from "./footer.module.sass"

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className={`columns is-variable is-0-touch is-3-desktop has-background-black is-vcentered is-mobile ${Styles.wrapper}`}>
      <div className={'column is-narrow text-primary has-text-left'}><span>&copy;{year}</span></div>
      <div className={'column has-text-right'}><Link to="/cookie">Мы используем куки файлы.</Link></div>
    </div>
  )
}
export default Footer
