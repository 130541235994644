import * as React from 'react'
import { Link } from 'gatsby'
import * as Styles from "./menu.module.sass"
import Icon from './icon'
import Button from './button'
import ContextConsumer from '../layouts/context'

const Menu = () => {
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <nav className={Styles.navmenu}>
          <Button aslink={true} onClick={() => set({ menuOpen: !data.menuOpen })} className={`is-hidden-desktop is-relative ${Styles.button}`}>
            {!data.menuOpen ? <span>Меню</span> : <Icon icon={['fas', 'times']} />}
          </Button>
          <div className={`is-flex-touch is-flex-direction-column is-justify-content-space-around ${!data.menuOpen ? `is-hidden-touch` : ``}`}>
            <ul className={`${Styles.mobilemenu}`}>
              <li><Link activeClassName={Styles.active} onClick={() => set({ menuOpen: false })} isCurrent={true} to="/">Главная</Link></li>
              <li><Link activeClassName={Styles.active} onClick={() => set({ menuOpen: false })} partiallyActive={true} to="/izdeliya">Изделия</Link></li>
              <li><Link activeClassName={Styles.active} onClick={() => set({ menuOpen: false })} partiallyActive={true} to="/magaziny">Магазины</Link></li>
              <li><Link activeClassName={Styles.active} onClick={() => set({ menuOpen: false })} partiallyActive={true} to="/kontakty">Контакты</Link></li>
            </ul>
          </div>
        </nav>
      )}
    </ContextConsumer>
  )
}
export default Menu
